<template>
  <div class="categories">
    <ul >
      <li>
        <div class="category-label">
          <span style="flex:1;"><b>取扱品目カテゴリ名</b></span>
        </div>
      </li>
      <li v-for="large in categories" v-bind:key="large.id" :class="{'is-open':large.isOpen}">
        <div class="category-label" :class="{'text-muted': focusSmall}">
          <span @click.prevent="openCategory(large)" :class="{'caret': large.childCount > 0, 'dot': !large.childCount > 0 }">
            {{large.categoryName}}
          </span>
        </div>
        <ul v-if="large.isOpen">
          <li v-for="mid in large.children" v-bind:key="mid.id" :class="{'is-open':mid.isOpen}">
            <div class="category-label" :class="{'text-muted': focusSmall}">
              <span @click.prevent="openCategory(mid)" :title="mid.categoryName" :class="{'caret': mid.childCount > 0, 'dot': !mid.childCount > 0}">
                {{mid.categoryName}}
                <span v-if="mid.categoryCode == '1090100'" class="badge-warning ml-1">認</span>
                <span v-if="mid.categoryCode == '1090100'" class="badge-check ml-1">署</span>
              </span>
            </div>
            <ul v-if="mid.isOpen">
              <li v-for="small in mid.children" v-bind:key="small.id" :class="{'is-selected': small.isSelected}">
                <div class="category-label">
                  <span @click.prevent="onSelect(large, mid, small)" :title="small.categoryName" :class="{'dot':true}">
                    {{small.categoryName}}
                    
                    <!-- <span v-if="small.license" class="badge-warning ml-1">認</span>
                    <span v-if="small.categoryCode==='1090102' || small.categoryCode==='1090101'" class="badge-dl ml-1" @click.prevent="dlTokuyaku();">特約あり</span> -->
                  </span>
                  <span v-if="hasCount" class="select-control" style="margin-top:9px;">{{small.productNameCount}}</span>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>
<style lang="scss" scoped>
  .categories {
    padding: 1rem .5rem 1rem .5rem;
    & > ul {
      padding:0;
    }
    ul {
      list-style-type: none;
      li{
        .category-label{
          display:flex;
        }
        .select-control{
          display:block;
          width: 48px;
          text-align: center;
          color: $biz-primary;
          margin: 5px 0;
        }
        .dot, .caret{
          display:block;
          flex:1;
          padding: .5rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 80%;
          overflow-x: hidden;
          &:hover{
            background: $biz-primary-light;
          }
          &:before {
            width: 16px;
            text-align: center;
            display: inline-block;
            margin-right: 7px;
          }
        }
        .dot {
          cursor: pointer;
          &:before {
            color: $biz-primary;
            content: "•"; }
        }
        .caret {
          cursor: pointer;
          &:before { content: "▶"; }
        }
        & > ul {
          display: none;
        }
        &.is-open {
          & > .category-label .caret:before {
            transform: rotate(90deg);
          }
          & > ul {
            display: block;
            border-left: 1px solid $biz-optional;
            padding: 0 0 0 30px;
            margin: 1rem 0 1rem 16px;
          } 
        }
        &.is-selected{
          background: #FFF0F1;
        }
      }
    }
  }  
</style>
<script>
  export default {
    props: ["categories", "focusSmall", "hasCount"],
    components: {  },
    methods:{
      onSelect(large, mid, small){
        this.$emit('onSelect', large, mid, small);
      },
      openCategory(category){
        this.$emit('onOpen', category);
      },
      dlTokuyaku(){
        var link = document.createElement("a");
        link.download = 'A1販売契約.pdf';
        link.href = '/policy/A1TOKUSAMPLE.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
</script>