<template>
  <div>
    <ul class="biz-breadcrumb" v-if="!isRegistrationWizard">
      <li><router-link to="/supplier/manage">{{$t('SYSTEM_MANAGEMENT.TITLE')}}</router-link></li>
      <li><a>{{$t('PRODUCT_NAME.TITLE')}}</a></li>
    </ul>
    <div class="page">
      <div class="page-title" v-if="!isRegistrationWizard">
        <h1>{{$t('PRODUCT_NAME.TITLE')}}</h1>
        <p>{{$t('PRODUCT_NAME.DESCRIPTION')}}</p>
      </div>
      <div class="card biz-primary mt-3">
        <div class="card-header">
          {{$t('PRODUCT_NAME.HEADER')}}
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-6 p-2">
              <div class="bg-white p-2">
                <div class="category-box">
                  <CategoryList 
                    :categories="categories"
                    :focusSmall="true"
                    :hasCount="true"
                    @onOpen="onOpen"
                    @onSelect="onSelect"/>
                </div>
              </div>
            </div>
            <div class="col p-2 d-flex" style="flex-direction:column;" v-if="selected.id">
              <div class="bg-white p-4">
                <h5>{{$t('PRODUCT_NAME.ADD_PRODUCT_NAME', {categoryName:selected.categoryName})}}</h5>
                <p class="small">{{$t('PRODUCT_NAME.ADD_PRODUCT_NAME_DESC')}}</p>
                <b-form-tags 
                  style="min-height: 150px;" 
                  :disabled="!selected.isSelected" 
                  :placeholder="$t('PRODUCT_NAME.ADD_PRODUCT_NAME_PLACEHOLDER')"
                  :duplicateTagText="$t('PRODUCT_NAME.DUPLICATE_TAG_TEXT')"
                  :invalidTagText="$t('PRODUCT_NAME.INVALID_TAG_TEXT')"
                  :add-button-text="$t('COMMON.ADD')" 
                  tag-variant="primary"
                  :tag-validator="tagValidator"
                  separator=",、"
                  v-model="selected.productNames"></b-form-tags>
              </div>
              <div class="bg-white p-4 mt-4" style="flex:1">
                <h5>{{$t('PRODUCT_NAME.REGISTERED_PRODUCT_NAMES', {categoryName:selected.categoryName})}}</h5>
                <div class="mt-4">
                  <b-badge 
                    class="mx-1"
                    style="font-size: 0.9rem;"
                    :variant="pn.isOrigin ? 'success' : 'light'"
                    v-for="(pn, index) in selected.oldProductNames" :key="index">{{pn.productName}}</b-badge>
                </div>
              </div>

              <div class="text-center mt-3">
                <j-button variant="primary" v-on:click="onProductNameSave()" :disabled="!selected.isSelected">
                  {{$t('COMMON.SAVE')}}
                </j-button>
                <br/>
                <a class="mt-2 d-block" href="javascript:void(0);" v-on:click="onCancel()" v-if="selected.isSelected">{{$t('COMMON.CANCEL')}}</a>
              </div>    
            </div>
            <div class="col p-2" v-else>
              <div class="p-4" style="background: #FFF4CD; height: 100%;">
                <div class="text-center mt-5">
                  <svg style="width: 20%; max-width: 140px;" viewBox="0 0 167 158" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M119.056 84.2972C143.582 62.7631 166.679 42.6084 166.679 25.2892C166.679 11.1119 155.17 0 140.486 0C132.232 0 124.215 3.75506 119.056 9.57923C113.818 3.75506 105.881 0 97.6261 0C82.9425 0 71.4337 11.1119 71.4337 25.2892C71.4337 42.6084 94.5306 62.7631 119.056 84.2972ZM97.6261 15.3268C101.118 15.3268 104.69 16.9361 106.992 19.5416L119.056 33.2591L131.121 19.5416C133.422 16.9361 136.994 15.3268 140.486 15.3268C146.36 15.3268 150.804 19.6183 150.804 25.2892C150.804 33.8721 134.613 49.5821 119.056 63.5294C103.499 49.5821 87.3079 33.7955 87.3079 25.2892C87.3079 19.6183 91.7526 15.3268 97.6261 15.3268Z" fill="#B6A97E"/>
                    <path d="M142.867 107.287H126.993C126.993 98.0912 121.04 89.8148 112.151 86.5962L63.2585 68.9704H0V153.268H47.6224V142.232L103.182 157.099L166.679 137.941V130.277C166.679 117.556 156.043 107.287 142.867 107.287ZM15.8741 137.941V84.2972H31.7483V137.941H15.8741ZM102.944 141.083L47.6224 126.292V84.2972H60.4011L106.595 100.927C109.294 101.923 111.119 104.452 111.119 107.287C111.119 107.287 95.3243 106.904 92.8638 106.138L73.9735 100.084L68.9732 114.644L87.8634 120.698C91.9113 122.001 96.118 122.691 100.404 122.691H142.867C145.963 122.691 148.741 124.453 150.011 126.982L102.944 141.083Z" fill="#B6A97E"/>
                  </svg>
                </div>
                <h4 class="text-center mt-5" style="font-size: 1.5rem;line-height: 2rem;">
                  {{$t('PRODUCT_NAME.EMPTY_STATE_MESSAGE_TITLE')}}
                </h4>
                <p class="text-center">{{$t('PRODUCT_NAME.EMPTY_STATE_MESSAGE')}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-5">
        <j-button variant="primary" size="lg" v-if="isRegistrationWizard" v-on:click="onSave">
          {{$t('WIZARD.FINISH')}}
        </j-button>
        <div class="mt-4" v-if="!isRegistrationWizard">
          <j-button variant="light" to="/supplier/manage">
            <font-awesome-icon icon="arrow-left"></font-awesome-icon>
            {{$t('COMMON.GO_BACK')}}
          </j-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  
  .categories {
    font-size: 1rem !important;
  }

  .category-box{
    margin-top: 10px;
    height: 700px;
    overflow-y: scroll;
  }

  .b-form-tags-button {
    min-width: max-content;
  }

  .badge-check{
    border: 1px solid $biz-primary;
    color: $biz-primary;
    background: #FFF;
    padding: 0 .5rem 0 .5rem;
  }
</style>
<script>
  import CompanyInfo from '@/model/CompanyInfo';
  import Category from '@/model/Category';
  import CategoryList from '@/components/category/CategoryList.vue';
  
  export default {
    props: ["isRegistrationWizard"],
    components: { CategoryList },
    data: () => ({
      categories: [],
      selected: {},
    }),
    created: function(){
      this.onLoad();
    },
    methods:{
      onLoad(){
        CompanyInfo.getCompanyCategory(this.$store.state.user.companyCode, true, false).then(result => {
        result.data.forEach((c) => {
          c.isOpen = true;
          if(c.children != null && c.children.length > 0)
            c.children.forEach((m) => {
              m.isOpen = true;
            })
        });
        this.categories = result.data;
      });
      },
      onProductNameSave(){
        Category.addProductName(this.selected.id, this.$store.state.user.companyCode, {
          productNames: this.selected.productNames.map(pn => { return {productName: pn, categoryId: this.selected.id} })
        }).then(()=>{
          this.$successMessage(this.$t('PRODUCT_NAME.SUCCESS_MESSAGE'));
          this.$set(this.selected, "isSelected", false);
          this.$set(this.selected, "productNames", []);
          this.selected = {};
          this.onLoad();
        }).catch(reason => this.$errorMessage(undefined, {reason}));
      },
      onSave(){
        CompanyInfo.changeStatus( this.$store.state.user.companyCode, {
          statusName: 'IS_PRODUCT_NAME_REGISTERED',
          status: true
        }).then(() => {
          this.$emit('onSave', 'CategoryInfo');
        }).catch(reason => this.$errorMessage(undefined, {reason}));
      },
      onCancel(){
        this.selected = {};
      },
      onSelect(large, mid, small){
        this.$set(this.selected, "isSelected", false);
        this.selected = {};
        if(small){
          this.selected = small;
          Category.getProductNames(this.selected.id).then(result => {
            this.selected.oldProductNames = result.data;
            this.$set(this.selected, "isSelected", true);
          }).catch(reason => this.$errorMessage(undefined, {reason}));
        }
      },
      onOpen(category){
        if(category.isOpen){
          this.$set(category, "isOpen", false);
        }else{
          this.$set(category, "isOpen", true);
          if(!category.children || category.children.length <= 0){
            Category.getChildCategory(category.id).then((result) => {
              this.$set(category, "children", result.data.children);
            });
          }
        }
      },
      tagValidator(tag){ 
        if(this.selected.oldProductNames.filter(pn => pn === tag).length > 0) return false;
        return true;
      }
    }
  }
</script>